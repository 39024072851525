.Card {
    

    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ;
    margin: 15px;
    
    
    display: flex;
    flex-direction: column;
    max-width: 370px;
    min-width: 370px;
   
}
.Card .title{
   
    padding: 5px 0px;
    justify-content: center;
    color:#fff;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    
}
.Card .content{
    flex-grow: 1;
   
    background-color:#fff;
    color: #000;
    padding: 20px;
    justify-content: center;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
}


