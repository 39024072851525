.main-footer {
    position: fixed;
    bottom:0px;
    width:100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

/*LOGO MENU*/
.text-sm .brand-link .brand-image {
    height: 29px;
    margin-bottom: -.25rem;
    margin-left: .20rem;
    margin-top: -.25rem;
}
.Card {
    

    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ;
    margin: 15px;
    
    
    display: -webkit-flex;
    
    
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 370px;
    min-width: 370px;
   
}
.Card .title{
   
    padding: 5px 0px;
    -webkit-justify-content: center;
            justify-content: center;
    color:#fff;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    
}
.Card .content{
    -webkit-flex-grow: 1;
            flex-grow: 1;
   
    background-color:#fff;
    color: #000;
    padding: 20px;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
}



.wrapper {
	background-color: #fff!important;
}

.login-box-body {
	background-color: #eee;
}

.login-box button {
	margin-left: 0px;
}

.login-box a:hover {
	cursor: pointer;
}

