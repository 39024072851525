.main-footer {
    position: fixed;
    bottom:0px;
    width:100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

/*LOGO MENU*/
.text-sm .brand-link .brand-image {
    height: 29px;
    margin-bottom: -.25rem;
    margin-left: .20rem;
    margin-top: -.25rem;
}